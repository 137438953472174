/* -------------------------------------------
   1) Import Inter or any modern bold font
-------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

header {
  position: relative; /* So we can absolutely-position the marketplace icon */
  width: 100%;
  height: 100px;
  background: transparent;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* --------------------------------------------
   Marketplace Icon Container pinned at far left
-------------------------------------------- */
.marketplace-icon-container {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #fff;
  color: #000;
  padding: 0.3rem 0.4rem;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  z-index: 1001; /* Ensure it's above the header */
}

.marketplace-icon-container:hover {
  background-color: #f2f2f2;
}

/* --------------------------------------------
   Glass "header-wrapper" centered horizontally
-------------------------------------------- */
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  max-width: 1200px;
  min-width: 800px; /* Prevent wrapping by setting a minimum width */
  height: 80px;
  margin: 0 auto;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(0.5rem);
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 0 1.4rem;
  overflow: hidden;
}

/* Logo section inside the glass container */
.logo-section {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 60px;
  width: auto;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.0));
}

/* Navigation links section */
.nav-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0; /* Prevent shrinking */
}

/* Navigation links */
.nav-section a {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #000; /* Default black text */
  text-decoration: none;
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: 1px solid transparent; /* Prevent jumping on hover */
  position: relative; /* Position relative for pseudo-element */
  overflow: hidden; /* Hide the pseudo-element */
  transition: color 0.3s ease, border-color 0.3s ease;
}

/* Hover effect for navigation links */
.nav-section a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ff6600; /* Orange wipe color */
  z-index: -1; /* Behind the text */
  transition: width 0.3s ease;
}

.nav-section a:hover {
  color: #fff; /* Text changes to white */
  border-color: #ff6600; /* Orange outline */
}

.nav-section a:hover::before {
  width: 100%; /* Expand the orange background */
}

/* Sign-out button section */
.signout-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0; /* Prevent shrinking */
}

/* Sign-out button */
.signout-button {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #000; /* Default black */
  background-color: transparent;
  border: none;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  position: relative; /* For hover animation */
  overflow: hidden; /* Hide pseudo-element */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Pseudo-element for hover effect on sign-out button */
.signout-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ff6600; /* Orange wipe color */
  z-index: -1;
  transition: width 0.3s ease;
}

.signout-button:hover {
  color: #fff; /* Text changes to white */
}

.signout-button:hover::before {
  width: 100%; /* Expand the orange wipe */
}

/* --------------------------------------------
   Responsive Adjustments
-------------------------------------------- */
/* Mobile styles */
@media (max-width: 768px) {
  /* Header container */
  header {
    padding: 0.5rem 0;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  /* Marketplace icon */
  .marketplace-icon-container {
    position: fixed;
    top: 1rem;
    left: 0.5rem;
    transform: none;
    padding: 0.25rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    background-color: #fff;
  }

  /* Main header wrapper */
  .header-wrapper {
    width: calc(95% - 2.5rem);
    min-width: unset;
    height: auto;
    margin: 0;
    margin-left: 2.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
  }

  /* Logo section */
  .logo-section {
    flex: 0 0 auto;
    max-width: 40px;
    display: flex;
    align-items: center;
  }

  .header-logo {
    width: 100%;
    height: auto;
    max-height: 30px;
    object-fit: contain;
  }

  /* Navigation section */
  .nav-section {
    flex: 1;
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    min-width: 0;
  }

  .nav-section::-webkit-scrollbar {
    display: none;
  }

  .nav-section a {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
    flex-shrink: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #000;
    text-decoration: none;
    border-radius: 4px;
    transition: color 0.3s ease, border-color 0.3s ease;
  }

  /* Sign out section */
  .signout-section {
    flex: 0 0 auto;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .signout-button {
    padding: 0.35rem !important;
    width: 2rem !important;
    height: 2rem !important;
    min-width: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .marketplace-icon-container {
    padding: 0.2rem;
  }

  .header-wrapper {
    width: calc(98% - 2rem);
    margin-left: 2rem;
    padding: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.75rem;
  }

  .logo-section {
    max-width: 35px;
  }

  .header-logo {
    max-height: 25px;
  }

  .nav-section {
    gap: 0.25rem;
  }

  .nav-section a {
    padding: 0.25rem 0.35rem;
    font-size: 0.75rem;
  }

  .signout-button {
    width: 1.75rem !important;
    height: 1.75rem !important;
    padding: 0.25rem !important;
    min-width: 1.75rem !important;
  }
}