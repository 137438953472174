.box {
  /* Same styling as before except no absolute positioning: */
  padding: .75rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(0.625rem);
  box-shadow: 0 -0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  /* You can keep the transition or remove it if you want to avoid any effect: */
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), top 0.1s linear;
  /* Removed position: absolute; and z-index since it's now static. */
}

.button {
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 11.25rem; /* original extended width */
  height: 2.5rem;  /* original height */
  border-radius: 1.25rem;
  position: relative;
  background-color: white;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  color: black;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 0.875rem;
}

/* We removed all .button.scrolled and .button.scrolled:hover rules */

.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
}

.text {
  position: absolute;
  left: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  transition: opacity 0.3s ease;
  opacity: 1;
  padding-left: 0.625rem;
  color: black;
  font-family: Arial, sans-serif;
  font-size: 0.875rem;
}

/* Segmented Control */
.rt-SegmentedControlRoot {
  width: 12rem !important;
  height: 2.3rem !important;
}

.rt-SegmentedControlItem {
  height: 100% !important;
  padding: 0 1rem !important;
  font-size: 0.875rem !important;
}

/* Hide mobile text by default */
.mobile-text {
  display: none;
}

/* MOBILE STYLES */
@media (max-width: 768px) {
  .box {
    width: 95%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.95);
  }

  .box > div {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 0.35rem !important;
    width: 100% !important;
    min-height: 2.25rem !important;
  }

  .button {
    height: 2.25rem !important;
    max-width: 110px !important;
    min-width: auto !important;
    flex: 0 1 auto !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 1.125rem !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    overflow: hidden !important;
  }

  .svg-container {
    width: 2rem !important;
    height: 2rem !important;
  }

  .text {
    padding: 0 0.5rem 0 0.25rem !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 0.75rem !important;
    max-width: 70px !important;
    line-height: 1 !important;
  }

  .rt-SegmentedControlRoot {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
    height: 2.25rem !important;
    flex: 0 0 auto !important;
    display: flex !important;
    justify-content: center !important;
  }

  .rt-SegmentedControlItem {
    height: 2.25rem !important;
    padding: 0 0.5rem !important;
    font-size: 0.75rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .desktop-text {
    display: none !important;
  }

  .mobile-text {
    display: inline !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .box {
    padding: 0.35rem;
  }

  .box > div {
    gap: 0.25rem !important;
  }

  .button {
    height: 2rem !important;
    max-width: 100px !important;
  }

  .svg-container {
    width: 1.75rem !important;
    height: 1.75rem !important;
  }

  .text {
    font-size: 0.7rem !important;
    max-width: 60px !important;
    padding: 0 0.4rem 0 0.2rem !important;
  }

  .rt-SegmentedControlRoot {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
    height: 2rem !important;
  }

  .rt-SegmentedControlItem {
    height: 2rem !important;
    padding: 0 0.35rem !important;
    font-size: 0.7rem !important;
  }

  .mobile-text {
    font-size: 0.7rem !important;
  }
}
