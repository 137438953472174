.pricing-container {
  max-width: 90rem;
  margin: 2rem auto;
  padding: 2rem;
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.page-header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 2rem 0;
  background: linear-gradient(90deg, #FF8C00, #FFA500);
  background-clip: text;
  -webkit-background-clip: text;
  color: white;
  letter-spacing: -0.02em;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pricing-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 700;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-wrapper {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 380px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  position: relative;
  overflow: visible;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

.card-wrapper.popular {
  border: 2px solid #FF4B2B;
  box-shadow: 0 8px 32px rgba(255, 75, 43, 0.15);
}

.popular-tag {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FF4B2B;
  color: white !important;
  padding: 4px 16px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(255, 75, 43, 0.25);
  z-index: 2;
}

.card-title {
  font-size: 2rem;
  font-weight: 600;
  color: #1a1a1a !important;
  margin-bottom: 1rem;
  text-shadow: none !important;
}

.price-container {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 2.5rem;
}

.price-amount {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a !important;
  text-shadow: none !important;
}

.price-period {
  color: #666 !important;
  font-size: 1rem;
  text-shadow: none !important;
}

.feature-list {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.feature-item {
  display: flex !important;
  align-items: center !important;
  gap: 1rem !important;
  padding: 0 !important;
  border-left: 2px solid transparent !important;
  transition: all 0.2s ease !important;
  position: relative !important;
  margin: 0 !important;
}

.feature-item * {
  color: #333333 !important;
  text-shadow: none !important;
  -webkit-text-stroke: 0 !important;
  font-size: 1rem !important;
}

.feature-item span {
  color: #333333 !important;
  text-shadow: none !important;
  -webkit-text-stroke: 0 !important;
}

.feature-item:hover {
  border-left-color: #FF4B2B;
  transform: translateX(4px);
}

.feature-item svg {
  color: #FF4B2B !important;
  flex-shrink: 0 !important;
  width: 20px !important;
  height: 20px !important;
}

.pricing-button {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: white !important;
  background-color: #FF4B2B !important;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 2.5rem;
}

.pricing-button:hover:not(:disabled) {
  background-color: #FF3517 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 75, 43, 0.25);
}

.pricing-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #FF8F7D !important;
}

.manage-subscription-button {
  position: relative;
  left: 80%;
  bottom: 1.25rem;
  padding: 0.75rem 1.5rem;
  color: #FF4B2B !important;
  background-color: white !important;
  border: 1px solid rgba(255, 75, 43, 0.2);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.manage-subscription-button:hover {
  background-color: rgba(255, 75, 43, 0.1) !important;
}

.gradient-background {
  background: linear-gradient(135deg, #FF8F7D 0%, #FFB067 50%, #FF8F7D 100%) !important;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Media Queries */
@media (max-width: 1200px) {
  .pricing-container {
    padding: 1.5rem;
  }

  .card-wrapper {
    width: 320px;
  }
}

@media (max-width: 768px) {
  .pricing-title {
    font-size: 2.5rem;
  }

  .card-wrapper {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  }

  .manage-subscription-button {
    left: auto;
    right: 1rem;
  }
}

@media (max-width: 480px) {
  .pricing-title {
    font-size: 2rem;
  }

  .card-wrapper {
    padding: 1.5rem;
  }

  .price-amount {
    font-size: 2rem;
  }
}