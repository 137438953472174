body {
    font-family: 'Courier', monospace;
  }
  
  textarea, input, button {
    font-family: 'Courier', monospace;
  }
  
  textarea::selection {
    background-color: rgba(255, 0, 102, 0.3);
    color: inherit;
  }
  
  textarea::-moz-selection {
    background-color: rgba(136, 107, 131, 0.3);
    color: inherit;
  }
  
  /* Layout styles */
  .app-container {
    position: relative;
    padding-top: 2rem;
    body {
    font-family: 'Courier', monospace;
  }
  
  textarea, input, button {
    font-family: 'Courier', monospace;
  }
  
  textarea::selection {
    background-color: rgba(255, 0, 102, 0.3);
    color: inherit;
  }
  
  textarea::-moz-selection {
    background-color: rgba(136, 107, 131, 0.3);
    color: inherit;
  }
  
  /* Layout styles */
  .app-container {
    position: relative;
    
  }
  
  .gradient-background {
    background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%);
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  /*.banner-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 9.375rem;
  }
  
  .orange-shape {
    content: "";
    position: absolute;
    top: auto;
    left: auto;
    width: 150%;
    height: 9.375rem;
    border-radius: 0 0 0% 0%;
    z-index: -1;
  }
  
  .white-overlay {
    width: auto;
    height: 12.5rem;
    position: absolute;
    top: auto;
    left: 25%;
    opacity: 1;
  }*/
  
  /* Button styles */
  .semi-transparent-button {
    background-color: rgba(255, 255, 255, 0.3);
    color: #333;
    border: 0.0625rem solid rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0.3125rem);
    box-shadow: none;
    transition: all 0.3s ease;
    font-family: Arial, sans-serif; /* Override Courier font */
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .semi-transparent-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border: 0.0625rem solid rgba(0, 0, 0, 0.3);
  }
  
  .semi-transparent-button:active {
    transform: translateY(0.0625rem);
  }
  
  .semi-transparent-button span {
    font-family: Arial, sans-serif;
  }
  
  /* Text area styles */
  .text-area-container {
    display: flex;
    align-items: center;
    position: relative;
    
  }
  
  .text-area {
    flex-grow: 1;
  }
  
  .text-area textarea {
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.625rem;
    font-family: 'Courier', monospace;
    outline: none;
    transition: 
      border-color 0.2s ease-in-out, 
      box-shadow 0.2s cubic-bezier(0.7, 0, 0.3, 1) 320ms;
    box-shadow: none;
  }
  
  .text-area textarea:focus {
    border-color: #FF8C00;
    box-shadow: 
      inset 0 0 0.3125rem rgba(255, 140, 0, 0.5),
      inset 0 0 0.625rem rgba(255, 140, 0, 0.3),
      0 0 0.3125rem rgba(255, 140, 0, 0.5),
      0 0 0.625rem rgba(255, 140, 0, 0.3);
    transition: 
      border-color 0.2s ease-in-out, 
      box-shadow 0.0s cubic-bezier(0, 0.7, 1, 0.3) 320ms;
  }
  
  
  
  .text-area textarea:focus {
    border-color: #FF8C00;
    box-shadow: 
      0 0 0.75rem rgba(255, 140, 0, 0.5),
      0 0 0.75rem rgba(255, 140, 0, 0.3);
  }
  
  
  
  /* Clear button styles */
  .clear-button, .button-container {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.5s ease;
  }
  
  .text-area-container:hover .clear-button,
  .text-area-container:hover .button-container {
    opacity: 1;
    transform: translateX(0);
  }
  
  .white-container {
    background-color: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }
  
  .wider-white-container {
    /* Inherit styles from .white-container */
    background-color: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    
    /* Additional styles specific to .wider-white-container */
    width: 100%;
    max-width: 112.5rem;
  }
  
  /* Floating box styles */
  .floating-box {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(0.625rem);
    box-shadow: 0 -0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
  }
  
  .floating-box.scrolled {
    left: 102%;
    width: auto;
  }
  
  .floating-box-button {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
    transition: all 0.3s ease, width 0.3s ease;
    height: 2.5rem;
    border-radius: 1.25rem;
    position: relative;
  }
  
  .floating-box-button .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: white;
    border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  }
  
  .floating-box-button .button-text {
    position: absolute;
    left: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.1s;
    padding-left: 0.625rem;
    opacity: 0;
  }
  
  .floating-box-button:hover .button-text {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
  
  .floating-box:not(.scrolled) {
    left: 5%;
    width: 90%;
  }
  
  /* Responsive styles */
  @media (max-width: 48rem) {
    /* Add responsive styles here */
  }
  
  textarea, input, button {
    font-family: 'Courier', monospace;
  }
  
  
  
  .text-area-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .text-area {
    flex-grow: 1;
  }
  
  .clear-button {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .text-area-container:hover .clear-button {
    opacity: 1;
    transform: translateX(0);
  }
  }
  
  .gradient-background {
    background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%);
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  /*.banner-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 9.375rem;
  }
  
  .orange-shape {
    content: "";
    position: absolute;
    top: auto;
    left: auto;
    width: 150%;
    height: 9.375rem;
    border-radius: 0 0 0% 0%;
    z-index: -1;
  }
  
  .white-overlay {
    width: auto;
    height: 12.5rem;
    position: absolute;
    top: auto;
    left: 25%;
    opacity: 1;
  }*/
  
  /* Button styles */
  .semi-transparent-button {
    background-color: rgba(255, 255, 255, 0.3);
    color: #333;
    border: 0.0625rem solid rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0.3125rem);
    box-shadow: none;
    transition: all 0.3s ease;
    font-family: Arial, sans-serif; /* Override Courier font */
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .semi-transparent-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border: 0.0625rem solid rgba(0, 0, 0, 0.3);
  }
  
  .semi-transparent-button:active {
    transform: translateY(0.0625rem);
  }
  
  .semi-transparent-button span {
    font-family: Arial, sans-serif;
  }
  
  /* Text area styles */
  .text-area-container {
    display: flex;
    align-items: center;
    position: relative;
    
  }
  
  .text-area {
    flex-grow: 1;
  }
  
  .text-area textarea {
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.625rem;
    font-family: 'Courier', monospace;
    outline: none;
    transition: 
      border-color 0.2s ease-in-out, 
      box-shadow 0.2s cubic-bezier(0.7, 0, 0.3, 1) 320ms;
    box-shadow: none;
  }
  
  .text-area textarea:focus {
    border-color: #FF8C00;
    box-shadow: 
      inset 0 0 0.3125rem rgba(255, 140, 0, 0.5),
      inset 0 0 0.625rem rgba(255, 140, 0, 0.3),
      0 0 0.3125rem rgba(255, 140, 0, 0.5),
      0 0 0.625rem rgba(255, 140, 0, 0.3);
    transition: 
      border-color 0.2s ease-in-out, 
      box-shadow 0.0s cubic-bezier(0, 0.7, 1, 0.3) 320ms;
  }
  
  
  
  .text-area textarea:focus {
    border-color: #FF8C00;
    box-shadow: 
      0 0 0.75rem rgba(255, 140, 0, 0.5),
      0 0 0.75rem rgba(255, 140, 0, 0.3);
  }
  
  
  
  /* Clear button styles */
  .clear-button, .button-container {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.5s ease;
  }
  
  .text-area-container:hover .clear-button,
  .text-area-container:hover .button-container {
    opacity: 1;
    transform: translateX(0);
  }
  
  .white-container {
    background-color: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }
  
  .wider-white-container {
    /* Inherit styles from .white-container */
    background-color: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2), 0 0 0 0.0625rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    
    /* Additional styles specific to .wider-white-container */
    width: 100%;
    max-width: 112.5rem;
  }
  
  /* Floating box styles */
  .floating-box {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(0.625rem);
    box-shadow: 0 -0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
  }
  
  .floating-box.scrolled {
    left: 102%;
    width: auto;
  }
  
  .floating-box-button {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
    transition: all 0.3s ease, width 0.3s ease;
    height: 2.5rem;
    border-radius: 1.25rem;
    position: relative;
  }
  
  .floating-box-button .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: white;
    border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  }
  
  .floating-box-button .button-text {
    position: absolute;
    left: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.1s;
    padding-left: 0.625rem;
    opacity: 0;
  }
  
  .floating-box-button:hover .button-text {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
  
  .floating-box:not(.scrolled) {
    left: 5%;
    width: 90%;
  }
  
  /* Responsive styles */
  @media (max-width: 48rem) {
    /* Add responsive styles here */
  }
  
  textarea, input, button {
    font-family: 'Courier', monospace;
  }
  
  
  
  .text-area-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .text-area {
    flex-grow: 1;
  }
  
  .clear-button {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .text-area-container:hover .clear-button {
    opacity: 1;
    transform: translateX(0);
  }