*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset Margin and Padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
img,
div,
section,
button {
  margin: 0;
  padding: 0;
}

.gradient-background {
  /* Remove or comment out the orange gradient */
  /* background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%); */
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Base styles */
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: #000000;
}

#root {
  position: relative;
  overflow-x: hidden;
}

.btn {
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  border: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* Override button styles for the floating CTA */
.btn-overlay-container .btn.btn-primary {
  width: 100%;
  margin: 0;
  background: transparent;
}

.btn-overlay-container .btn.btn-primary::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 107, 53, 0.9);
  z-index: 1;
  transition: background 0.5s ease;
}

.btn-overlay-container .btn.btn-primary::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(51, 158, 224, 0.85);
  z-index: 3;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.btn-overlay-container:hover .btn.btn-primary::after {
  transform: translateX(0);
}

/* Ensure video overlay works correctly */
.btn-overlay-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
}

.btn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 2;
  transform: scale(7);
  transform-origin: center center;
}

/* Ensure text stays on top */
.btn-text {
  position: relative;
  z-index: 4;
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
}
/* Main Landing Page Container */
.landing-page {
  display: flex;
  flex-direction: column;
  color: #000000;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

/* Hero Container */
.hero-container {
  position: relative;
  min-height: 85vh;
  overflow: hidden;
}

/* Video Background */
.hero-container .video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160%;
  height: 160%;
  transform: translate(-35%, -55%) perspective(2000px) rotateY(45deg) rotateX(15deg);
  transform-origin: center;
  z-index: -3;
  overflow: hidden;
}

.hero-container .background-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Black Overlay */
.hero-container .black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: -1;
}

/* Content Wrapper */
.content-wrapper {
  position: relative;
  z-index: 1;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
}

/* Hero Section */
.hero-section {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7rem 0;
  gap: 4rem;
  min-height: 600px;
  box-sizing: border-box;
}

/* Hero Content (Left Side) */
.hero-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 60%;
  box-sizing: border-box;
  position: absolute;
  left: 2%;
  top: 55%;
  transform: translateY(-50%);
  padding-top: 1rem;
}

/* Hero Logo */
.hero-logo {
  max-width: min(2000px, 100%);
  height: auto;
  margin-bottom: 0rem;
  display: block;
}

/* Hero Text Group */
.hero-text-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.0rem;
}

/* Hero Text */
.hero-text {
  font-size: clamp(1.1rem, 1.5vw, 1.3rem);
  line-height: 1.6;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

/* Features List */
.hero-features-list .hero-feature-item {
  display: -moz-inline-stack;
  align-items: center;
  gap: 0.8rem;
  flex-direction: column;
  white-space: nowrap;
  word-spacing: normal;
  font-size: clamp(1.3rem, 1.2vw, 1.1rem);
  line-height: 1.7;
  padding: 0.25rem 0.5rem;
  border-left: 3px solid rgb(255, 255, 255);
  transition: all 0.5s ease;
  font-weight: 600;
  color: #ffffff !important; /* Ensure text is white */
  opacity: 1;

}

.hero-features-list .hero-feature-item::before {
  content: '';
  font-size: 1.5em;
  line-height: 1;
  background: linear-gradient(to right, #ffffff 50%, #ff6b35 50%);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: all 0.5s ease;
  opacity: 1;
}

.hero-features-list .hero-feature-item .hero-feature-item-text {
  background: #ffffff;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: all 0.5s ease;
  display: inline-block;
  font-weight: 600;
  text-shadow: none;
  width: 100%;
  opacity: 1;
  padding: 0;
  margin: 0;
}

.hero-features-list .hero-feature-item:hover {
  border-left-color: #ff6b35;
  transform: translateX(8px);
}

.hero-features-list .hero-feature-item:hover::before,
.hero-features-list .hero-feature-item:hover .hero-feature-item-text {
  background-position: -100% 0;
}
/* CTA Wrapper */
.hero-cta-wrapper {
  position: absolute;
  right: 5%;
  top: 57%;
  transform: translateY(-50%);
  width: 400px;
  background: #ffffff;
  padding: 1rem;
  border-radius: 16px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.5s ease;
}

/* Gradient Border */
.hero-cta-wrapper::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: 16px;
  background: linear-gradient(135deg, #ff6b35, #ffc107);
  z-index: -1;
  transition: background 0.5s ease;
}

.hero-cta-wrapper:hover::before {
  background: linear-gradient(135deg, #339ee0, #4bd4f4);
}

/* Inner CTA Background */
.hero-cta {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #000000;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  transition: background 0.5s ease;
}

.hero-cta-wrapper:hover .hero-cta {
  background: #001f33;
}

.hero-cta .btn {
  width: 100%;
}

/* Button Overlay Container */
.btn-overlay-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Grain Overlay */
.btn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 2;
  transform: scale(7);
  transform-origin: center center;
}

/* Buttons */


.btn-text {
  position: relative;
  z-index: 4;
}

/* Primary Button (Join the Beta) */
.btn-primary {
  background: transparent;
}

.btn-primary::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 107, 53, 0.9);
  z-index: 1;
  transition: background 0.5s ease;
}

.btn-primary::after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(51, 158, 224, 0.85);
  z-index: 3;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.hero-cta-wrapper:hover .btn-primary::after {
  transform: translateX(0);
}

.btn-primary .btn-text {
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(51, 158, 224, 0.4);
}

.hero-cta-wrapper:hover .btn-primary::before {
  background: rgba(51, 158, 224, 0.85);
}

/* Secondary Button (See Pricing) */
.btn-secondary {
  background: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
  text-shadow: none;
}

.btn-secondary:hover {
  background: #f0f0f0;
  transform: translateY(-2px);
}

/* Image Section */
.image-section {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem 0;
  overflow: hidden;
  box-shadow: 0 20px 20px 20px rgba(0, 0, 0, 0.5);
}

.image-section-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  z-index: 2;
  pointer-events: none;
}

/* Image Container */
.image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  perspective: 1000px;
  transition: transform 0.5s ease;
  width: 100%;
  padding: 0 2rem;
  box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, 0.5);
}

/* Image Wrapper */
.image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 45rem;
  position: relative;
  box-shadow: 0 -20px 20px -20px rgba(0, 0, 0, 0.5);
}

/* Centered Image */
.centered-image {
  max-width: auto;
  height: 100%;
  display: block;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(2000px) rotateY(-30deg) rotateX(8deg);
  transition: transform 0.5s ease;
}

.centered-image:hover {
  transform: translate(-50%, -50%) scale(1.05);
}

/* Text Box */
.text-box {
  flex: 1;
  max-width: 45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.0);
}

/* Feature Video Styles */
.feature-video-container {
  width: 80%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
}

.feature-video-container.tilt-left {
  transform: rotateY(0deg);
}

.feature-video-container.tilt-right {
  transform: rotateY(0deg);
}

.feature-video-container:hover {
  transform: rotateY(0deg);
}

.feature-video {
  width: 100%;
  height: auto;
  min-height: 450px;
  display: block;
  border-radius: 12px;
  object-fit: cover;
}

.video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transform-style: preserve-3d;
}

.play-button {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  color: white;
}


.feature-video-container:hover .play-button {
  transform: rotateY(0deg);
}

.play-button:hover {
  transform: scale(1.1);
}

.feature-video-container:hover .video-thumbnail-overlay {
  opacity: 0;
  pointer-events: none;
}

.feature-video.loaded {
  opacity: 1;
}

.feature-video-container.tilt-left .feature-video {
  transform: rotateY(3deg) translateZ(0);
}

.feature-video-container.tilt-right .feature-video {
  transform: rotateY(-3deg) translateZ(0);
}

.feature-video-container:hover .feature-video {
  transform: rotateY(0deg) translateZ(50px) scale(1.1);
}
/* Feature Video Pairs */
.feature-video-pairs {
  display: flex;
  flex-direction: column;
  gap: 6rem; /* Increased gap between pairs */
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.feature-video-pair {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  gap: 3rem; /* Added gap between video and text */
}

/* Feature Box Adjustments */
.feature-box {
  background-color: rgba(255, 255, 255, 0.08);
  padding: 2rem;
  border-radius: 8px;
  width: 40%; /* Reduced to accommodate larger video */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.feature-box h3 {
  color: #ffffff;
  margin-bottom: 1rem;
}

.feature-box p {
  color: rgba(255, 255, 255, 0.8);
}

.feature-box-center {
  width: 60%;
  margin: 0 auto;
}

/* Third Section */
.third-section {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  color: #ffffff;
}

.third-section h2 {
  color: #ffffff;
}


.third-section-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* Additional Section */
.additional-section {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  color: #ffffff;
}

/* Fourth Section */
.fourth-section {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  color: #ffffff;
}

.fourth-section-content {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

/* Fourth CTA Wrapper */
.fourth-cta-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content-wrapper {
    padding: 0 1.5rem;
  }

  .hero-section {
    padding: 4rem 0;
    gap: 3rem;
  }

  .hero-content {
    max-width: 45%;
    left: 5%;
  }

  .feature-video-container {
    width: 50%;
  }

  .feature-box {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .content-wrapper {
    padding: 0 0.75rem;
  }

  .hero-section {
    padding: 1.5rem 0;
  }

  .hero-text {
    font-size: 1rem;
  }

 

  /* Adjust CTA buttons for small screens */
  .btn {
    padding: 0.75rem 1rem;
  }

  .image-container {
    padding: 1rem;
    flex-direction: column; /* Stack image and text vertically */
  }

  .centered-image {
    max-width: 100%;
  }

  .text-box {
    background-color: rgba(255, 255, 255, 0.08);
    color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }


  /* Update section transitions */
.image-section,
.third-section,
.additional-section,
.fourth-section {
  position: relative;
  margin: 2rem 0;
  overflow: hidden;
}

/* Add subtle texture overlay to sections */
.image-section::before,
.third-section::before,
.additional-section::before,
.fourth-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noiseFilter)' opacity='0.1'/%3E%3C/svg%3E");
  opacity: 0.15;
  pointer-events: none;
}
  /* Third Section Adjustments for Extra Small Screens */
  .feature-box {
    padding: 1.5rem;
    font-size: 1rem;
  }

  .feature-image {
    margin-top: 1rem;
  }

  /* Fourth Section Adjustments for Extra Small Screens */
  .fourth-section-content {
    padding: 0 0.75rem;
  }
}

/* Additional Sections */
.additional-section {
  /* Updated background to match the image section's gradient */

  padding: 4rem 2rem;
  color: #333;
}

.additional-section .section-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.additional-section h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333333;
}

.additional-section p,
.additional-section ul {
  font-size: 1.1rem;
  line-height: 1.6;
}

.additional-section ul {
  list-style-type: disc;
  margin-left: 2rem;
  text-align: left;
}

/* Fourth CTA Wrapper */
.fourth-cta-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.pricing-section {
  background-color: #000000;
  padding: 6rem 2rem;
  color: #ffffff;
  scroll-margin-top: 2rem;
}

.pricing-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #ffffff;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.pricing-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, border-color 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 255, 255, 0.2);
}

.pricing-card.popular {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.2);
}

.popular-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #1a1a1a;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #ffffff;
}

.price-container {
  margin: 1.5rem 0;
}

.price {
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
}

.period {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 0.5rem;
}

.subscribe-button {
  width: 100%;
  padding: 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
}

.subscribe-button:hover {
  background-color: #c82333;
}

.main-features {
  margin: 2rem 0;
  padding: 1rem 0;
  border-top: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.4;
}

.feature-item span {
  color: #28a745;
  flex-shrink: 0;
  margin-top: 0.2rem;
}

.feature-item.highlight {
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: 500;
}

.features-list {
  flex-grow: 1;
}

/* Mobile styles for Landing page */
@media (max-width: 768px) {
  /* Hero Section */
  .hero-section {
    flex-direction: column;
    padding: 2rem 0;
    min-height: auto;
  }

  .hero-content {
    width: 100% !important;
    max-width: 100% !important;
    padding: 1rem 0 !important;
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }

  .landing-page .hero-content img.hero-logo {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
    padding: 0 1rem !important;
    margin: 0 auto 1rem;
  }

  .hero-text {
    font-size: 1rem !important;
    text-align: left !important;
    padding: 0 1rem !important;
    margin-bottom: 1.5rem !important;
  }

  .hero-text-group {
    width: 100% !important;
    padding: 0 !important;
  }

  .hero-features-list {
    margin-top: 1.5rem;
    width: 100%;
    padding: 0 1rem;
  }

  .hero-feature-item {
    display: block !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    line-height: 1.4 !important;
    max-width: 100% !important;
    border-left: none !important;
    text-align: left !important;
    padding: 0.75rem 0 !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3) !important;
  }

  .hero-feature-item:last-child {
    border-bottom: none !important;
  }

  .hero-cta-wrapper {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    width: 100%;
    max-width: 400px;
    margin: 2rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-cta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Video Background */
  .hero-container .video-background {
    transform: translate(-50%, -50%) perspective(1000px) rotateY(0) rotateX(0);
    width: 200%;
    height: 200%;
  }

  .btn-overlay-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .btn.btn-primary {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .btn.btn-secondary {
    width: 100%;
    max-width: 300px;
    margin: 1rem auto 0;
  }

  /* Features Section */
  .feature-video-pair {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  .feature-box {
    width: 100%;
    max-width: 500px;
    order: 1;
    text-align: center;
    padding: 1.5rem;
  }

  .feature-video-container {
    width: 100%;
    max-width: 500px;
    order: 2;
  }

  .feature-video {
    min-height: 250px;
  }

  /* Pricing Section */
  .pricing-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem;
  }

  .pricing-card {
    margin: 0 auto;
    max-width: 400px;
  }

  /* Image Section */
  .image-section {
    padding: 2rem 1rem;
  }

  .image-container {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }

  .image-wrapper {
    height: auto;
    min-height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .centered-image {
    position: relative;
    max-width: 90%;
    width: auto;
    height: auto;
    left: auto;
    transform: none;
    margin: 0 auto;
    display: block;
  }

  .text-box {
    font-size: 2rem;
    padding: 1.5rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Third Section */
  .third-section-content {
    padding: 1rem;
  }

  .feature-video-pairs {
    gap: 3rem;
  }
}

/* Additional adjustments for extra small screens */
@media (max-width: 480px) {
  .hero-text {
    font-size: 0.9rem !important;
  }

  .hero-feature-item {
    font-size: 0.9rem !important;
    padding: 0.5rem 0 !important;
  }

  .landing-page .hero-content img.hero-logo {
    padding: 0 0.5rem !important;
  }

  .feature-video {
    min-height: 200px;
  }

  .image-section {
    padding: 1rem 0.5rem;
  }

  .image-wrapper {
    min-height: 200px;
  }

  .centered-image {
    max-width: 95%;
  }

  .text-box {
    font-size: 1.5rem;
    padding: 1rem;
  }

  .pricing-card {
    padding: 1.5rem;
  }

  .btn {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }
}